<template>
  <v-card flat>
    <v-card-title>
      {{ $capitalize($tc("model.final_report_card_title")) }}
      <v-spacer />
      <ScopeProvider scope="leads.final_report.add">
        <v-btn outlined color="primary" :to="{ name: new_report_route }">
          <v-icon>mdi-plus</v-icon>
          {{ $capitalize($tc("model.final_report_new_button")) }}
        </v-btn>
      </ScopeProvider>
    </v-card-title>
    <v-data-table
      :headers="headers_final_reports"
      :items="final_reports"
      :loading="fetching_final_reports"
      hide-default-footer
    >
      <template v-slot:item.integration="{ item }">
        <span v-if="isReportIntegrated(item)">
          <v-icon color="green">mdi-check</v-icon>
          {{ $capitalize($tc("model.final_report_integrated_info")) }}
        </span>
        <span v-else>
          {{ $capitalize($tc("model.final_report_not_integrated_info")) }}
        </span>
      </template>
      <template v-slot:item.established_facts="{ item }">
        <span
          :title="item.established_facts"
          style="width: 200px"
          class="d-inline-block text-truncate"
        >
          {{ item.established_facts }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="$store.getters['auth/getScope']('leads.final_report.read')"
          icon
          @click="openFinalReport(item)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          icon
          :href="item.attachment && item.attachment.public_url"
          target="_blank"
          ><v-icon>mdi-file-find</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { lead_types } from "@/services/lead_types";
import { index as indexFinalReports } from "@/services/final_reports";

import ScopeProvider from "@/components/ScopeProvider";

export default {
  components: { ScopeProvider },
  data: function () {
    return {
      fetching_final_reports: false,
      final_reports: [],
      headers_final_reports: [
        {
          text: this.$capitalize(
            this.$tc("model.final_report_report_column_table")
          ),
          value: "attachment.description",
        },
        {
          text: this.$capitalize(
            this.$tc("model.final_report_established_facts_table")
          ),
          value: "established_facts",
        },
        {
          text: this.$capitalize(
            this.$tc("model.final_report_integration_column_table")
          ),
          value: "integration",
        },
        {
          text: "Origem",
          value: "source_name",
          sortable: true,
          width: 120,
        },
        {
          text: "Responsável",
          value: "user_name",
          sortable: true,
          width: 120,
        },
        {
          text: "Criação",
          value: "created_at",
          sortable: true,
          width: 150,
        },
        {
          text: this.$capitalize(
            this.$tc("model.final_report_action_column_table")
          ),
          value: "actions",
          sortable: false,
          width: 120,
        },
      ],

      //Budgets By Coverage
      fetching_budgets_by_coverage: false,
    };
  },
  methods: {
    async fetchFinalReports(lead_id) {
      this.fetching_final_reports = true;
      try {
        const response = await indexFinalReports(lead_id);
        this.final_reports = response.data;
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: "Não foi possível carregar os relatórios finais",
          color: "red",
        });
      } finally {
        this.fetching_final_reports = false;
      }
    },
    openFinalReport(final_report) {
      this.$router.push({
        name: this.view_report_route,
        params: { final_report_id: final_report.id },
      });
    },
    isReportIntegrated(report) {
      return report.attachment && report.attachment?.external_reference != null;
    },
  },
  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
    is_multirisks() {
      return this.lead.lead_type == lead_types.multirisks;
    },
    is_eletric_risk() {
      return this.lead.lead_type == lead_types.riscos_eletricos;
    },
    new_report_route() {
      if (this.is_multirisks) {
        return "create_multirisks_final_report";
      } else if (this.is_eletric_risk) {
        return "create_eletric_risk_final_report";
      }
      return "";
    },
    view_report_route() {
      if (this.is_multirisks) {
        return "view_multirisks_final_report";
      } else if (this.is_eletric_risk) {
        return "view_eletric_risk_final_report";
      }
      return "";
    },
  },
  created() {
    if (this.lead?.id) {
      this.fetchFinalReports(this.lead?.id);
    }
  },
  watch: {
    lead: function (value, oldValue) {
      const old_lead_id = oldValue?.id;
      const lead_id = value?.id;

      if (old_lead_id == null && lead_id != null) {
        this.fetchFinalReports(lead_id);
      }
    },
  },
};
</script>

<style></style>
