import Vue from "vue";

export async function index(lead_id) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_id}/final_reports`
  );

  return response.data;
}

export async function storeMultirisks(lead_id, payload) {
  const response = await Vue.prototype.$http.post(
    `/leads/${lead_id}/final_reports/multirisks`,
    payload
  );

  return response.data;
}

export async function storeEletricRisk(lead_id, payload) {
  const response = await Vue.prototype.$http.post(
    `/leads/${lead_id}/final_reports/eletric_risk`,
    payload
  );

  return response.data;
}

export async function show(lead_id, report_id) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_id}/final_reports/${report_id}`
  );

  return response.data;
}

export async function showLatestReport(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/final_reports/latest`
  );

  return response.data;
}
